import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import store from "@/state/store";
import i18n from "@/i18n";
import {
  KcmCategory,
  KcmCategoryResponse,
  KcmCategoryResult,
  KcmContent,
  KcmContentArrayResponse,
  KcmContentArrayResult,
  KcmContentResponse,
  KcmContentResult,
} from "@/types";
//import store from "../../state/store";
const baseUrl = process.env.VUE_APP_CONTENT_API_ROOT
  ? process.env.VUE_APP_CONTENT_API_ROOT.replace(/\/$/, "")
  : "";

const industry = process.env.VUE_APP_INDUSTRY_ID ?? 1;
/**
 * Define the keys in the env file used for different
 * access levels
 */
/* const AccessLevels = {
  standard: process.env.VUE_APP_CONTENT_API_KEY, 
}; */
/**
 * Define a list of known errors when working with
 * the content api
 */
const Errors = {
  ContentTypesErr: "unable to load content types",
  ContentsErr: "unable to load content",
  CategoriesErr: "unable to load categories",
};
function headers(): AxiosRequestConfig {
  const override = new URLSearchParams(window.location.search).get("override");
  if (override)
    return {
      auth: { username: "", password: override },
    };
  return {
    auth: { username: "", password: process.env.VUE_APP_CONTENT_API_KEY ?? "" },
  };
}
//const INDUSTRY = 1;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function get(resource: string): Promise<AxiosResponse<any, any>> {
  return axios.get(baseUrl + resource, headers());
}

export const contentService = {
  getContents,
  getContentID,
  getContentSlug,
  getContentVideoID,
  getCategories,
  getRss,
  getDate,
  getDateVideo,
  getDateLink,
  getDaySuffix,
  appendPPC,
  patchSTMLinks,
  patchExternalLinks,
  generateLink,
  generateVideoLink,
  generatePersonalizedVideoLink,
  getPublishedBeforeParameter,
  categoryText,
  contentHasCategory,
  localeToString,
};

function getContents(
  page = 0,
  limit = 8,
  type = "blog",
  language = i18n.locale,
  categories = "",
  query = "",
  publishedSince = "",
  publishedBefore = ""
): Promise<KcmContentArrayResult> {
  const endpoint =
    "/industries/" +
    industry +
    "/content/" +
    (query ? "search/" : "") +
    "?page=" +
    page +
    (!query ? "&sort_by=published_at" : "") +
    "&limit=" +
    limit +
    "&types=" +
    type +
    "&language=" +
    language +
    (categories ? "&categories=" + categories : "") +
    (query ? "&query=" + query : "") +
    (publishedSince ? "&published_since=" + publishedSince : "") +
    getPublishedBeforeParameter(publishedBefore);

  return get(endpoint)
    .then((result: KcmContentArrayResponse) => {
      if (result.data?.content) {
        result.data.content.forEach((pieceOfContent) => {
          appendPPC(pieceOfContent);
          patchSTMLinks(pieceOfContent);
        });
        return result.data;
      } else if (result.data?.search_results) {
        result.data.content = result.data.search_results;
        result.data.content.forEach((pieceOfContent) => {
          appendPPC(pieceOfContent);
          patchSTMLinks(pieceOfContent);
        });
        return result.data;
      } else {
        return { error: "No content found matching these parameters" };
      }
    })
    .catch(() => {
      return { error: Errors.ContentsErr };
    });
}

function getContentID(
  id = 1,
  language = i18n.locale
): Promise<KcmContentResult> {
  const endpoint =
    "/industries/" + industry + "/content/" + id + "?language=" + language;

  return get(endpoint)
    .then((result: KcmContentResponse) => {
      if (result.data?.content) {
        if (!store.getters["auth/authStatusNotPaused"]) {
          if (!store.getters["pp/pausedAt"]) {
            return { error: "No content found matching these parameters" };
          } else if (
            new Date(result.data.content.published_at) >
            new Date(store.getters["pp/pausedAt"])
          ) {
            return { error: "No content found matching these parameters" };
          }
        }
        appendPPC(result.data.content);
        patchSTMLinks(result.data.content);
        return result.data;
      } else {
        return { error: "No content found matching these parameters" };
      }
    })
    .catch(() => {
      return { error: Errors.ContentsErr };
    });
}

function getContentSlug(
  slug = "a-majority-of-consumers-say-its-a-good-time-to-sell-your-house",
  language = i18n.locale
): Promise<KcmContentResult> {
  const endpoint =
    "/industries/" +
    industry +
    "/content/slug-" +
    slug +
    "?language=" +
    language;

  return get(endpoint)
    .then((result: KcmContentResponse) => {
      if (result.data?.content) {
        if (!store.getters["auth/authStatusNotPaused"]) {
          if (!store.getters["pp/pausedAt"]) {
            return { error: "No content found matching these parameters" };
          } else if (
            new Date(result.data.content.published_at) >
            new Date(store.getters["pp/pausedAt"])
          ) {
            return { error: "No content found matching these parameters" };
          }
        }
        appendPPC(result.data.content);
        patchSTMLinks(result.data.content);
        return result.data;
      } else {
        return { error: "No content found matching these parameters" };
      }
    })
    .catch(() => {
      return { error: Errors.ContentsErr };
    });
}

function getContentVideoID(
  videoId = "8da7778d",
  page = 0,
  limit = 1,
  type = "videos",
  language = i18n.locale
): Promise<KcmContentArrayResult> {
  const endpoint =
    "/industries/" +
    industry +
    "/content/" +
    "?page=" +
    page +
    "&limit=" +
    limit +
    "&types=" +
    type +
    "&language=" +
    language +
    (videoId ? "&api_v1_id=" + videoId : "") +
    getPublishedBeforeParameter();

  return get(endpoint)
    .then((result: KcmContentArrayResponse) => {
      if (result.data?.content) {
        result.data.content = result.data.content.filter((pieceOfContent) => {
          return !(
            !store.getters["auth/authStatusNotPaused"] &&
            (!store.getters["pp/pausedAt"] ||
              new Date(pieceOfContent.published_at) >
                new Date(store.getters["pp/pausedAt"]))
          );
        });
        if (result.data.content.length < 1)
          return { error: "No content found matching these parameters" };
        result.data.content.forEach((pieceOfContent) => {
          appendPPC(pieceOfContent);
        });
        return result.data;
      } else {
        return { error: "No content found matching these parameters" };
      }
    })
    .catch(() => {
      return { error: Errors.ContentsErr };
    });
}

function getCategories(
  page = 0,
  limit = 30,
  language = "all"
): Promise<KcmCategoryResult> {
  const endpoint =
    "/industries/" +
    industry +
    "/categories/" +
    "?page=" +
    page +
    "&limit=" +
    limit +
    "&language=" +
    language;

  return get(endpoint)
    .then((result: KcmCategoryResponse) => {
      if (result.data?.categories) {
        return result.data;
      } else {
        return { error: "No content found matching these parameters" };
      }
    })
    .catch(() => {
      return { error: Errors.ContentsErr };
    });
}

function getRss(
  ppcode: null | undefined | string = null,
  categories = "",
  language = i18n.locale,
  timestamp = Date.now()
): string {
  const endpoint =
    "/industries/" +
    industry +
    "/content/rss" +
    "?t=" +
    timestamp +
    "&sort_by=published_at" +
    "&types=blog" +
    (ppcode ? "&link_suffix=%3Fa%3D" + ppcode : "") +
    "&language=" +
    language +
    (categories ? "&categories=" + categories : "") +
    getPublishedBeforeParameter();

  return endpoint;
}

function getDate(contentDate: string): string {
  let locale = "";
  if (i18n.locale === "en") {
    locale = "en-us";
  } else if (i18n.locale === "es") {
    locale = "es-us";
  }

  const date = new Date(contentDate);
  if (date.toString() === "Invalid Date") return date.toString();

  const weekday = date.toLocaleDateString(locale, {
    weekday: "long",
  });
  const month = date.toLocaleDateString(locale, {
    month: "long",
  });
  const day = date.toLocaleDateString(locale, {
    day: "numeric",
  });
  const year = date.toLocaleDateString(locale, {
    year: "numeric",
  });
  return weekday + " " + month + " " + getDaySuffix(day) + ", " + year;
}

function getDateVideo(contentDate: string): string {
  let locale = "";
  if (i18n.locale === "en") {
    locale = "en-us";
  } else if (i18n.locale === "es") {
    locale = "es-us";
  }

  const date = new Date(contentDate);
  if (date.toString() === "Invalid Date") return date.toString();

  const month = date.toLocaleDateString(locale, {
    month: "long",
  });
  const day = date.toLocaleDateString(locale, {
    day: "numeric",
  });
  const year = date.toLocaleDateString(locale, {
    year: "numeric",
  });
  return month + " " + day + ", " + year;
}

function getDateLink(contentDate: string): string {
  const date = new Date(contentDate);
  if (date.toString() === "Invalid Date") return date.toString();

  const month = date.toLocaleDateString("en-us", {
    month: "2-digit",
  });
  const day = date.toLocaleDateString("en-us", {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    minimumIntegerDigits: 2,
    day: "2-digit",
  });
  const year = date.toLocaleDateString("en-us", {
    year: "numeric",
  });
  return "/" + year + "/" + month + "/" + day + "/";
}

function getDaySuffix(day: string): string {
  let output = "";
  if (["1", "21", "31"].indexOf(day) !== -1) {
    output = day + "st";
  } else if (["2", "22"].indexOf(day) !== -1) {
    output = day + "nd";
  } else if (["3", "23"].indexOf(day) !== -1) {
    output = day + "rd";
  } else {
    output = day + "th";
  }
  return output;
}

function appendPPC(pieceOfContent: KcmContent): void {
  if (store.getters["pp/ppCode"]) {
    const regex =
      /((href="https:\/\/www\.simplifyingthemarket\.com\/[^"]*)|(href="\/[^"]*))([^<>])*/;
    const replace = new RegExp(regex, "g");
    if (pieceOfContent.description) {
      pieceOfContent.description = pieceOfContent.description.replace(
        replace,
        "$1?a=" + store.getters["pp/ppCode"] + '"'
      );
    }
    if (pieceOfContent.contents) {
      pieceOfContent.contents = pieceOfContent.contents.replace(
        replace,
        "$1?a=" + store.getters["pp/ppCode"] + '"'
      );
    }
  }
}

function patchSTMLinks(content: KcmContent): void {
  if (content.contents) {
    content.contents = content.contents.replaceAll(
      "https://www.simplifyingthemarket.com/wp-content",
      "https://api.simplifyingthemarket.com/wp-content"
    );
    content.contents = content.contents.replaceAll(
      "https://www.simplifyingthemarket.com",
      ""
    );
    content.contents = patchExternalLinks(content.contents);
  }
  if (content.description) {
    content.description = content.description.replaceAll(
      "www.simplifyingthemarket.com",
      ""
    );

    content.description = patchExternalLinks(content.description);
  }
}

function patchExternalLinks(text: string): string {
  const regex = /<a[\s]+([^>]+)>((?:.(?!<\/a>))*.)<\/a>/g;
  const links = text.match(regex);

  //if any links were found
  if (links) {
    //loop through the links
    for (const link of links) {
      /** if the link includes http (rules out link that have been made relative) and doesn't include the current domain */
      if (link.includes("http") && !link.includes(window.location.hostname)) {
        const patch = link.replace("<a ", "<a target='_blank'");
        text = text.replace(link, patch);
      }
    }
  }

  return text;
}

function generateLink(content: KcmContent): string {
  let route =
    "/" + i18n.locale + getDateLink(content.published_at) + content.slug + "/";
  if (store.getters["pp/ppCode"]) {
    route = route + `?a=` + store.getters["pp/ppCode"];
  }
  return route;
}

function generateVideoLink(content: KcmContent): string {
  let route = "/" + i18n.locale + "/videos/" + content.slug + "/";
  if (store.getters["pp/ppCode"]) {
    route = route + `?a=` + store.getters["pp/ppCode"];
  }
  return route;
}
// https://videos.mykcm.com/members/<user_id>/videos/mp4/<slug>.mp4?t=<timestamp>
function generatePersonalizedVideoLink(
  content: KcmContent,
  userId: number | string
): string {
  if (userId === 0 || userId === "0") {
    return content.contents;
  }

  const contentsSplit = content.contents.split("/");
  const route =
    "https://videos.mykcm.com/members/" +
    userId +
    "/videos/mp4/" +
    contentsSplit[contentsSplit.length - 1] +
    "?t=" +
    Date.now();

  return route;
}

function getPublishedBeforeParameter(date?: string): string {
  let dateQuery = date ?? "";
  if (!store.getters["auth/authStatusNotPaused"]) {
    const req = Date.parse(dateQuery);
    const pausedAt = Date.parse(store.getters["pp/pausedAt"]);

    if (!date || pausedAt < req) {
      dateQuery = store.getters["pp/pausedAt"];
    }
  }

  if (!date) {
    return "";
  }

  return "&published_before=" + encodeURIComponent(dateQuery);
}

function categoryText(item: KcmCategory): string {
  if (i18n.locale === "en" && item && item.name) {
    return item.name;
  } else if (i18n.locale === "es" && item.translations?.es?.name) {
    return item.translations.es.name;
  } else {
    return (
      "No Translation For Category: " +
      (item && item.name ? item.name : "invalid category object")
    );
  }
}

function contentHasCategory(content: KcmContent, check: string): boolean {
  if (content.categories) {
    return (
      // filters the categories down to an array containing
      // only those with the matching slug then check to see if
      // that array has any length. if so then the content has
      // the category
      content.categories.filter((category) => category.slug === check).length >
      0
    );
  }
  return false;
}

function localeToString(key: string): string {
  return i18n.t(key) as string;
}
