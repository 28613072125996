import { AuthState, AuthUserFeature } from "@/types";
import { Commit } from "vuex";

const getDefaultState = (): AuthState => {
  const userID = window.localStorage.getItem("userID") ?? "";
  const status = window.localStorage.getItem("status") ?? "";
  const features = window.localStorage.getItem("features") ?? "";
  return {
    userID: userID ? JSON.parse(userID) : 0,
    status: status ? JSON.parse(status) : "inactive",
    features: features ? JSON.parse(features) : [],
  };
};

export const state = getDefaultState();

export const mutations = {
  USERID(state: AuthState, newValue: number): void {
    state.userID = newValue;
    saveState("userID", state.userID);
  },

  STATUS(state: AuthState, newValue: string): void {
    state.status = newValue;
    saveState("status", state.status);
  },

  FEATURES(state: AuthState, newValue: AuthUserFeature[]): void {
    state.features = newValue;
    saveState("features", state.features);
  },
};

export const getters = {
  authUserID(state: AuthState): number {
    return state.userID;
  },

  authStatus(state: AuthState): string {
    return state.status;
  },

  authFeatures(state: AuthState): AuthUserFeature[] {
    return state.features;
  },

  authFeaturesSlugs(state: AuthState): string[] {
    if (!state.features) {
      return [];
    }

    let featureSlugs = state.features.map((feature) => feature.slug);

    const featuresWithChildren = state.features.filter(
      (feature) => feature.includes !== null
    );

    const childFeatureSlugs = [].concat(
      ...(featuresWithChildren.map((feature) =>
        feature.includes.map((child) => child.slug)
      ) as [])
    );

    if (childFeatureSlugs.length > 0) {
      featureSlugs = [...new Set([...featureSlugs, ...childFeatureSlugs])];
    }
    return featureSlugs;
  },

  authIsBasic(state: AuthState): boolean {
    return getters.authFeaturesSlugs(state).includes("basic");
  },

  authIsVideo(state: AuthState): boolean {
    return getters.authFeaturesSlugs(state).includes("video");
  },

  authStatusNotPaused(state: AuthState): boolean {
    return getters.authStatus(state) !== "paused";
  },
};

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  setAuthUserID({ commit }: { commit: Commit }, newValue: number): void {
    commit("USERID", newValue);
  },

  setAuthStatus({ commit }: { commit: Commit }, newValue: string): void {
    commit("STATUS", newValue);
  },

  setAuthFeatures(
    { commit }: { commit: Commit },
    newValue: AuthUserFeature[]
  ): void {
    commit("FEATURES", newValue);
  },
};

function saveState(key: string, state: any): void {
  window.localStorage.setItem(key, JSON.stringify(state));
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
