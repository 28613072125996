
import appConfig from "@/app.config.json";
import { profileComputed } from "@/state/helpers";
import { defineComponent } from "vue";

interface ComponentData {
  headerHeight: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bodyStyles: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  listener: any;
}

export default defineComponent({
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title: string): string {
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    },
  },
  computed: {
    ...profileComputed,
  },
  data(): ComponentData {
    return {
      headerHeight: 120,
      bodyStyles: "",
      listener: null,
    };
  },
  created() {
    this.createFBPixel();
    this.installGoogleTagManager();
    this.attachGA4Script();
  },
  mounted() {
    this.setBodyStyleAndListener();
  },
  watch: {
    bodyStyles(newStyles): void {
      document.body.style.setProperty(
        "--profileColor",
        newStyles["--profileColor"]
      );
      document.body.style.setProperty("--boxShadow", newStyles["--boxShadow"]);
    },
    profilePP(): void {
      this.setBodyStyleAndListener();
    },

    fbPixel(): void {
      this.removeFBPixel();
    },
    /**
     * Clear the alert message on route change
     */
    // eslint-disable-next-line no-unused-vars
    $route(): void {
      this.$nextTick(() => {
        this.setBodyStyleAndListener();
      });
    },
  },
  methods: {
    getProfilePP() {
      return this.profilePP;
    },
    calculateBodyStyles() {
      const styles = {
        "--profileColor": "#000000",
        "--boxShadow": "#000000",
        "--wrapperMargin": "120px",
      };
      if (this.loadingPP && !this.profilePP) {
        styles["--profileColor"] = "#000000";
        styles["--boxShadow"] = "#000000";
      } else if (
        this.profilePP &&
        this.profilePP.basic &&
        this.profilePP.basic.primary_color &&
        this.profilePP.basic.primary_color.value
      ) {
        styles["--profileColor"] =
          "#" + this.profilePP.basic.primary_color.value;
        styles["--boxShadow"] = this.convertColor(
          this.profilePP.basic.primary_color.value,
          -0.2
        );
      } else {
        styles["--profileColor"] = "#00aeef";
        styles["--boxShadow"] = this.convertColor("#00aeef", -0.2);
      }

      styles["--wrapperMargin"] = this.headerHeight + "px";

      return styles;
    },
    setBodyStyleAndListener(): void {
      const header = document.getElementById("navbar");
      if (header) {
        this.headerHeight = header.clientHeight;
        this.listener = window.onresize = (): void => {
          const header = document.getElementById("navbar");
          this.headerHeight = header ? header.clientHeight : 120;
          this.bodyStyles = this.calculateBodyStyles();
        };
      }
      this.bodyStyles = this.calculateBodyStyles();
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    convertColor(hex: any, lum: any) {
      hex = String(hex).replace(/[^0-9a-f]/gi, "");
      if (hex.length < 6) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      lum = lum || 0;

      var rgb = "#",
        c,
        i;
      for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16);
        c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
        rgb += ("00" + c).substr(c.length);
      }

      return rgb;
    },

    createFBPixel() {
      if (!this.inIframe()) {
        if (this.fbPixel) {
          this.facebookProvidedPixelCode(this.fbPixel);
        } else if (!this.profilePP) {
          this.facebookProvidedPixelCode(
            process.env.VUE_APP_KCM_FACEBOOK_PIXEL_ID ?? ""
          );
        }
      }
    },

    removeFBPixel() {
      try {
        if (
          window.fbq &&
          window.fbq.getState() &&
          window.fbq.getState().pixels &&
          window.fbq.getState().pixels.length > 0
        ) {
          window.fbq.getState().pixels.pop();
          this.createFBPixel();
        } else {
          this.createFBPixel();
        }
      } catch (error) {
        console.log("Failed to pop facebook pixel.");
        console.log(error);
      }
    },

    // Ignoring typescript and linting for facebook provided code.
    facebookProvidedPixelCode(pixelID: string) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
      !(function (f: any, b: any, e: any, v: any, n: any, t: any, s: any) {
        if (f.fbq) return;
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.setAttribute("id", "facebook-pixel-id");
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );
      window.fbq("init", pixelID);
      window.fbq("track", "PageView");
    },

    inIframe() {
      try {
        return window.self !== window.top;
      } catch {
        return true;
      }
    },
    installGoogleTagManager() {
      let script1 = document.createElement("script");
      script1.type = "text/javascript";
      script1.setAttribute("data-pagespeed-no-defer", "");
      script1.setAttribute("data-cfasync", "false");
      script1.innerHTML =
        "//<![CDATA[\r\n	var gtm4wp_datalayer_name = 'dataLayer1';" +
        "var dataLayer1 = dataLayer1 || [];//]]>';";
      document.head.appendChild(script1);

      let script2 = document.createElement("script");
      script2.id = "GoogleTagManagerEditScript";
      script2.type = "text/javascript";
      script2.setAttribute("data-pagespeed-no-defer", "");
      script2.setAttribute("data-cfasync", "false");
      script2.innerHTML =
        "/*This is edited in the mounted component on each view.*/";
      document.head.appendChild(script2);

      let script3 = document.createElement("script");
      script3.type = "text/javascript";
      script3.setAttribute("data-cfasync", "false");
      script3.innerHTML =
        "//<![CDATA[\r\n(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':" +
        "new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0]," +
        "j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=" +
        "'https://www.googletagmanager.com/gtm.'+'js?id='+i+dl;f.parentNode.insertBefore(j,f);" +
        "})(window,document,'script','dataLayer','" +
        process.env.VUE_APP_GTAG_ID +
        "');//]]";
      document.head.appendChild(script3);
    },
    attachGA4Script() {
      let script1 = document.createElement("script");
      script1.type = "text/javascript";
      script1.async = true;
      script1.src =
        "https://www.googletagmanager.com/gtag/js?id=" +
        process.env.VUE_APP_GA4_ID;
      document.head.appendChild(script1);

      let script2 = document.createElement("script");
      script2.type = "text/javascript";
      script2.innerHTML =
        "window.dataLayer = window.dataLayer || [];\n" +
        " function gtag() { dataLayer.push(arguments);}\n" +
        "gtag('js', new Date());\n" +
        "gtag('config', '" +
        process.env.VUE_APP_GA4_ID +
        "',\n" +
        " { send_page_view: false,\n " +
        "// Leave next comment for dev debugging purposes.\n" +
        "// debug_mode: true\n});";
      document.head.appendChild(script2);
    },
  },
});
