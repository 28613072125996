import { AuthApiResp, AuthApiResult } from "@/types";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
//import store from "../../state/store";
const baseUrl = process.env.VUE_APP_AUTH_API_ROOT
  ? process.env.VUE_APP_AUTH_API_ROOT.replace(/\/$/, "")
  : "";
/**
 * Define the keys in the env file used for different
 * access levels
 */
/* const AccessLevels = {
  standard: process.env.VUE_APP_CONTENT_API_KEY,
}; */
/**
 * Define a list of known errors when working with
 * the content api
 */
const Errors = {
  AuthErr: "unable to load auth",
};
const headers: AxiosRequestConfig = {
  auth: {
    username: process.env.VUE_APP_AUTH_API_USER ?? "",
    password: process.env.VUE_APP_AUTH_API_KEY ?? "",
  },
};
//const INDUSTRY = 1;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function get(resource: string): Promise<AxiosResponse<any, any>> {
  return axios.get(baseUrl + resource, headers);
}

export const authService = {
  getAuth,
};

function getAuth(userID = "0"): Promise<AuthApiResult> {
  if (userID === "0") {
    return Promise.resolve({ error: Errors.AuthErr });
  }
  const endpoint = "/users/" + userID + "/?expand=access_levels,features";

  return get(endpoint)
    .then((result: AuthApiResp) => {
      if (
        result.data &&
        result.data.user &&
        result.data.user.access_levels &&
        result.data.user.access_levels.filter((level) => {
          return level.industry === 1 || level.industry === 2;
        }).length > 0
      ) {
        return result.data;
      } else {
        return { error: "No user found matching these parameters" };
      }
    })
    .catch(() => {
      return { error: Errors.AuthErr };
    });
}
